let backendHost;
// const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;
console.log("HostName: " + hostname)

if (hostname === 'proagfarmanalyzerapp.azurewebsites.net') {
  backendHost = process.env.REACT_APP_BACKEND_SERVICE_PRODUCTION;
  // } else if(hostname === 'staging.realsite.com') {
  //   backendHost = 'https://staging.api.realsite.com';
  // } else if(/^qa/.test(hostname)) {
  //   backendHost = `https://api.${hostname}`;
} else if (hostname === 'proagfarmanalyzerapp-staging.azurewebsites.net') {
  backendHost = process.env.REACT_APP_BACKEND_SERVICE_STAGING;
} else {
   // backendHost = 'http://localhost:8000';
  backendHost = process.env.REACT_APP_BACKEND_SERVICE_PRODUCTION
}

export const API_ROOT = `${backendHost}`;