import React from "react";
import CanvasJSReact from '../../charts/canvasjs.react'
import {  Card, CardBody, CardTitle, Spinner } from 'reactstrap';
import { useHailHistory } from '../../hooks/useAthenium';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const HailHistory = ({ title = 'Title', location = { latitude: 45, longitude: -90 },filterDateRange }) => {

    const [isLoading, weatherData] = useHailHistory(location, filterDateRange);

    const options = {
        theme: "light1", // "light1", "dark1", "dark2"
        animationEnabled: true,
        zoomEnabled: true,
        exportEnabled: true,
        height: 250,
        axisX:{
            labelAngle: 50,
        },
        axisY: {
            title: "Diameter",
            suffix: "in"
        },
        data: [{
            type: "column",
            color: "#6D78AD",
            dataPoints: weatherData
        }]
    }

    return (
        <div>
            <Card>
                <CardBody className="text-center">
                    <CardTitle align="center" tag="h5">{title}</CardTitle>
                        {!isLoading ? <CanvasJSChart options={options} /> : <Spinner color="primary" />}
                </CardBody>
            </Card>
        </div>
    )

}

export default HailHistory;