import  React, {useState} from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import DailyPrecipitation from '../../components/WeatherHistory/DailyPrecipitation';
import DailyTemperature from '../../components/WeatherHistory/DailyTemperature';
import GrowingDegreeDayHistory from '../../components/WeatherHistory/GrowingDegreeDayHistory';

const TabbedViewWeatherHistory = ({ title = 'Title', location, filterDateRange }) => {

        const [activeTab, setActiveTab] = useState('1');

        const toggle = tab => {
            if (activeTab !== tab) setActiveTab(tab);
        }

        return (
            <div>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                Daily Precipitation
          </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                Daily Temperature
          </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >
                                Growing Degree Days
          </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <DailyPrecipitation title="Daily Precipitation" location={location} filterDateRange={filterDateRange}></DailyPrecipitation>
                        </TabPane>
                        <TabPane tabId="2">
                            <DailyTemperature title="Daily Temperature" location={location} filterDateRange={filterDateRange}></DailyTemperature>
                        </TabPane>
                        <TabPane tabId="3">
                            <GrowingDegreeDayHistory title="Growing Degree Days" location={location} filterDateRange={filterDateRange}></GrowingDegreeDayHistory>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        )


    }

    export default TabbedViewWeatherHistory;