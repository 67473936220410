import React from "react";
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useGaugeRisk } from '../../hooks/useAthenium';
// import CanvasJSReact from '../../charts/canvasjs.react';
import GaugeChart from 'react-gauge-chart';


// var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const chartStyle = {

}

const ClimateScores = ({ title = 'Title', location = { latitude: 45, longitude: -90 }, droughtScore = 0 }) => {

    const [isLoading, riskScoreResults] = useGaugeRisk(location);

    // const options = {
    //     theme: "light1", // "light1", "dark1", "dark2"
    //     exportEnabled: true,
    //     height: 150,
    //     axisX: {
    //         labelAngle: 50,
    //     },
    //     axisY: {
    //         title: "Risk",
    //         maximum: 10
    //     },
    //     data: [{
    //         type: "column",
    //         color: "#6D78AD",
    //         dataPoints: riskScoreResults
    //     }]
    // }

    if (!isLoading) {
        return (
            <div className="row row-margin-05 no-gutters">
                <div className="col-2">
                    <Card>
                        <CardBody>
                            <CardTitle align="center">{riskScoreResults[0].peril}</CardTitle>
                            <GaugeChart id="gauge-chart1"
                                nrOfLevels={11}
                                hideText={true}
                                style={chartStyle}
                                percent={riskScoreResults[0].riskScore / 10}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className="col-2">
                    <Card>
                        <CardBody>
                            <CardTitle align="center">{riskScoreResults[1].peril}</CardTitle>
                            <GaugeChart id="gauge-chart2"
                                nrOfLevels={11}
                                hideText={true}
                                style={chartStyle}
                                percent={riskScoreResults[1].riskScore / 10}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className="col-2">
                    <Card>
                        <CardBody>
                            <CardTitle align="center">{riskScoreResults[2].peril}</CardTitle>
                            <GaugeChart id="gauge-chart3"
                                nrOfLevels={11}
                                hideText={true}
                                style={chartStyle}
                                percent={riskScoreResults[2].riskScore / 10}
                            />
                        </CardBody>
                    </Card>
                </div>

                <div className="col-2">
                    <Card>
                        <CardBody>
                            <CardTitle align="center">{riskScoreResults[3].peril}</CardTitle>
                            <GaugeChart id="gauge-chart4"
                                nrOfLevels={10}
                                hideText={true}
                                style={chartStyle}
                                percent={riskScoreResults[3].riskScore / 10}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className="col-2">
                    <Card>
                        <CardBody>
                            <CardTitle align="center">{riskScoreResults[4].peril}</CardTitle>
                            <GaugeChart id="gauge-chart4"
                                nrOfLevels={10}
                                hideText={true}
                                style={chartStyle}
                                percent={riskScoreResults[4].riskScore / 10}
                            />
                        </CardBody>
                    </Card>
                </div>
                <div className="col-2">
                    <Card>
                        <CardBody>
                            <CardTitle align="center">{riskScoreResults[5].peril}</CardTitle>
                            <GaugeChart id="gauge-chart4"
                                nrOfLevels={10}
                                hideText={true}
                                style={chartStyle}
                                percent={riskScoreResults[5].riskScore / 10}
                            />
                        </CardBody>
                    </Card>
                </div>
                {/* <div className="col-md-2">
            <Card>
                <CardBody>
                    <CardTitle align="center">DROUGHT</CardTitle>
                    <GaugeChart id="gauge-chart4"
                            nrOfLevels={10}
                            hideText={true}
                            style={chartStyle}
                        percent={droughtScore}
                    />
                </CardBody>
            </Card>
            </div>
            <div className="col-md-2"></div> */}
            </div>

        )

    } else {
        return <div></div>
    }
}
export default ClimateScores;