import React, { useEffect, useRef, useState } from 'react';
import { loadModules } from 'esri-loader';
import './MapView.css';

export const HomeMap = ({ selectedLocation, setSelectedLocation, setFieldGeometry, policyGrowerSearch, selectedReinsuranceYear }: any) => {
  const mapRef = useRef(null);
  const [selectedFieldId, setSelectedFieldId] = useState(0);
  const fieldsLayerPopupTemplate = {
    // autocasts as new PopupTemplate()
    title: "{reinsuranceyear}-{stateabbreviation}-{policynumber}    {farmserialnumber}-{tractnumber}-{fieldnumber}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "agencyname",
            label: "Agency"
          },
          {
            fieldName: "growername",
            label: "Grower"
          },
          {
            fieldName: "commodityname",
            label: "Commodity"
          },
          {
            fieldName: "plantedacres",
            label: "Planted Acres"
          },
          {
            fieldName: "plantdate",
            label: "Planted Date"
          },
          {
            fieldName: "practiceabbreviation",
            label: "Practice"
          },
          {
            fieldName: "cluacres",
            label: "CLU Acres"
          }
        ]
      }
    ]
  };

  useEffect(() => {
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/FeatureLayer',
      'esri/identity/OAuthInfo',
      'esri/identity/IdentityManager',
      "esri/widgets/Locate",
      "esri/rest/support/Query",
      "esri/geometry/Circle"
    ], { css: true })
      .then(([WebMap, MapView, FeatureLayer, OAuthInfo, esriId, Locate, Query]) => {
        const oAuthInfo = new OAuthInfo({
          appId: process.env.REACT_APP_ARCGIS_PORTAL_ID,
          portalUrl: process.env.REACT_APP_ARCGIS_PORTAL_URL
        });
        esriId.registerOAuthInfos([oAuthInfo]);

        esriId
          .checkSignInStatus(oAuthInfo.portalUrl + '/sharing')
          .then(console.log("Logged in to ESRI"))
          .catch(/*give user an option to sign in*/);

        const map = new WebMap({
          basemap: "oceans"
          // portalItem: {
          //   id: '3d7f967f8c07407997ee1085e838ec55',
          //   portal: oAuthInfo.portalUrl
          // }
        });

        const view = new MapView({
          container: mapRef.current,
          zoom: 4,
          center: [-98, 35],
          popup: {
            dockEnabled: true,
            defaultPopupTemplateEnabled: false,
            dockOptions: {
              buttonEnabled: false,
              breakpoint: false
            }
          },
          map
        });

        const proAgFieldsLayer = new FeatureLayer({
          url: process.env.REACT_APP_ARCGIS_FEATURELAYER_PROAGCLU,
          name: "FieldsLayer",
          definitionExpression: "reinsuranceyear=" + Number(selectedReinsuranceYear) ,
          minScale: 2000000,
          maxScale: 0,
          popupTemplate: fieldsLayerPopupTemplate,
          symbol: {
            type: 'simple-fill',
            color: '#004c23',
            style: 'solid',

            outline: {
              color: 'red',
              width: 2
            }
          }
        });

        if (policyGrowerSearch) {
          proAgFieldsLayer.definitionExpression = "reinsuranceyear=" + Number(selectedReinsuranceYear) + " and (growerName like '%" + policyGrowerSearch + "%' or policynumber = '" + policyGrowerSearch + "')";
        }

        map.add(proAgFieldsLayer, 1);

        proAgFieldsLayer.when(() => {
          return proAgFieldsLayer.queryExtent();
        })
          .then((response) => {
            view.goTo(response.extent);
          });

        view.when(() => {
          init()
        })

        async function init() {
          await initCommonLandUnitLayer()
          await initSearch()
          await initBasemapGallery()
        }

        async function initCommonLandUnitLayer() {
          await loadModules([
            'esri/layers/FeatureLayer',
            'esri/layers/GraphicsLayer',
            'esri/Graphic',
            "esri/geometry/Circle"
          ], { css: true })
            .then(([FeatureLayer, GraphicsLayer, Graphic, Circle]) => {

              const cluLayer = new FeatureLayer({
                url: process.env.REACT_APP_ARCGIS_FEATURELAYER_MASTERCLU,
                name: "CluLayer",
                opacity: 0.20,
                minScale: 100000,
                maxScale: 0,
              })

              map.add(cluLayer);

              const tempGraphicLayer = new GraphicsLayer({
                name: "tempGraphicsLayer",
                opacity: 0.35
              })
              map.add(tempGraphicLayer, 1);

              view.on('click', (evt: any) => {
                if (tempGraphicLayer) tempGraphicLayer.removeAll();
                const queryParams = cluLayer.createQuery();
                queryParams.geometry = evt.mapPoint;
                queryParams.outFields = '*';
                cluLayer.queryFeatures(queryParams).then((result: any) => {
                  if (result.features.length > 0) {
                    var selectedFeature = result.features[0];
                    const centroid = selectedFeature.geometry.centroid;

                    // console.log(selectedFieldId)
                    // console.log(selectedFeature.attributes.clu_identifier)

                    // if (selectedFieldId === selectedFeature.attributes.clu_identifier) {
                    //   tempGraphicLayer.opacity = 0;
                    // } else {

                    //   setSelectedFieldId(selectedFeature.attributes.clu_identifier);

                    const tempGraphic = new Graphic({
                      geometry: selectedFeature.geometry,
                      type: 'polygon',
                      symbol: {
                        type: 'simple-fill',
                        color: '#00758C',
                        style: 'solid',
                        outline: {
                          color: '#FCB426',
                          width: 1
                        }
                      }
                    })

                    const circleGraphic = new Graphic({
                      geometry: new Circle({
                        center: centroid,
                        radius: 1,
                        radiusUnit: "kilometers"
                      }),
                      type: 'polygon',
                      symbol: {
                        type: 'simple-fill',
                        color: 'red',
                        style: 'none',
                        outline: {
                          color: '#00758C',
                          width: 2
                        }
                      }
                    })

                    tempGraphicLayer.graphics.add(tempGraphic);
                    tempGraphicLayer.graphics.add(circleGraphic)

                    setFieldGeometry(tempGraphic.geometry);
                    setSelectedLocation({ latitude: centroid.latitude, longitude: centroid.longitude });
                    // }
                  }
                });
              });
            });
        }

        async function initSearch() {
          await loadModules([
            'esri/widgets/Search'
          ], { css: true })
            .then(([Search]) => {

              const search = new Search({
                view,
                includeDefaultSources: true,
                popupEnabled: false
              });

              view.ui.add(search, 'top-right');
              view.ui.move('zoom', 'top-left');
            });
        }


        async function initBasemapGallery() {
          await loadModules([
            'esri/widgets/BasemapGallery',
            'esri/widgets/Expand'
          ], { css: true })
            .then(([BasemapGallery, Expand]) => {
              const basemapGallery = new BasemapGallery({
                view
              });

              const basemapGalleryExpand = new Expand({
                expandIconClass: 'esri-icon-basemap',
                expandTooltip: 'Expand Basemap Gallery',
                view,
                content: basemapGallery
              });

              view.ui.add(basemapGalleryExpand, 'top-left');
            });
        }

        return () => {
          if (view) {
            view.destroy();
          }
        };
      });
  }, [policyGrowerSearch, selectedReinsuranceYear]
  );


  return <div className='map-view-component' ref={mapRef}>

  </div>

};