import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup,  InputGroupText, Input } from 'reactstrap';
import './ComponentFilters.css';
import Moment from 'moment';

export const DateFilter = ({ filterDateRange, setFilterDateRange,showDateFilterModal, setShowDateFilterModal }: any) => {

    type DateRange = {
        startDate: string
        endDate: string;
    };

    useEffect(
        () => {

        }, []
    );

    const defaultDateRange: DateRange = {
        startDate: filterDateRange.startDate,
        endDate: filterDateRange.endDate
    };

    const [dateRange, setDateRange] = useState(defaultDateRange);

    const toggle = () => setShowDateFilterModal(!showDateFilterModal);

    const onUserChange = <P extends keyof DateRange>(prop: P, value: DateRange[P]) => {
        setDateRange({ ...dateRange, [prop]: value });
    };

    function submitButtonClicked(event: any) {
        event.preventDefault();
        setFilterDateRange({ startDate: Moment(dateRange.startDate).utc(), endDate: Moment(dateRange.endDate).utc() });
        toggle();
    }

    return <div>
        
        <Modal isOpen={showDateFilterModal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Date Range Filter</ModalHeader>
            <ModalBody>
                <form onSubmit={submitButtonClicked} >
                    <div className="row">
                        <div className="col-md-12">
                            <InputGroup>
                                    <InputGroupText>Start Date: </InputGroupText>
                                <Input
                                    name='startDate'
                                    type='date'
                                    value={Moment(dateRange.startDate).format('YYYY-MM-DD')}
                                    onChange={e => {
                                        onUserChange('startDate', e.target.value);
                                    }} />
                            </InputGroup>
                            <br></br>
                            <InputGroup>
                                    <InputGroupText>  End Date: </InputGroupText>
                                <Input
                                    name='endDate'
                                    type='date'
                                    value={Moment(dateRange.endDate).format('YYYY-MM-DD')}
                                    onChange={e => {
                                        onUserChange('endDate', e.target.value);
                                    }} />
                            </InputGroup>
                        </div>
                    </div>

                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={submitButtonClicked}>Apply Filter</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </div>;
}

export default DateFilter;


