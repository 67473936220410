import { useEffect, useState } from 'react';
import Moment from 'moment';
import { useOktaAuth } from '@okta/okta-react';
import { API_ROOT } from '../api-config';

export const useAverageDailyTemperature = (location, filterDateRange) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [TemperatureHistory, setTemperatureHistory] = useState<Object[]>([]);

    useEffect(() => {
        async function fetchHailHistory() {
            const url = `${API_ROOT}/api/clearag/gettemperaturehistory/${encodeURIComponent(location.latitude)}/${encodeURIComponent(location.longitude)}?startDate=${encodeURIComponent(Moment(filterDateRange.startDate).format('MM/DD/YYYY'))}&endDate=${encodeURIComponent(Moment(filterDateRange.endDate).format('MM/DD/YYYY'))}`;
            var beginingDate = filterDateRange.startDate;
            const fetchData: Object[] = [];
            setTemperatureHistory([]);
            if (!authState) return null;
            if (authState.isAuthenticated) {
                const accessToken = oktaAuth.getAccessToken();
                await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => data.forEach(listItem => {

                        fetchData.push({
                            label: Moment(beginingDate).format('MM-DD-YYYY'),
                            y: [+ parseFloat(listItem.air_temp_min_avg).toFixed(1), + parseFloat(listItem.air_temp_max_avg).toFixed(1)]
                        });
                        beginingDate = addDays(beginingDate,1)
                    }))
            }
            setTemperatureHistory(fetchData);
        }

        fetchHailHistory();
    }, [location, filterDateRange]);

    return [TemperatureHistory];
};

export const useClimateHistory = (location, filterDateRange) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [ClimateHistory, setClimateHistory] = useState<ClimateHistory[]>([]);

    useEffect(() => {
        async function fetchHailHistory() {
            const url = `${API_ROOT}/api/clearag/gettemperaturehistory/${encodeURIComponent(location.latitude)}/${encodeURIComponent(location.longitude)}?startDate=${encodeURIComponent(Moment(filterDateRange.startDate).format('MM/DD/YYYY'))}&endDate=${encodeURIComponent(Moment(filterDateRange.endDate).format('MM/DD/YYYY'))}`;
            var beginingDate = filterDateRange.startDate;
            const fetchData: ClimateHistory[] = [];
            setClimateHistory([]);
            if (!authState) return null;
            if (authState.isAuthenticated) {
                const accessToken = oktaAuth.getAccessToken();
                await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => data.forEach(listItem => {

                        fetchData.push({
                            LocalDate: Moment(beginingDate).format('MM-DD-YYYY'),
                            MinAirTempAverage: + parseFloat(listItem.air_temp_min_avg).toFixed(2),
                            MaxAirTempAverage: + parseFloat(listItem.air_temp_max_avg).toFixed(2),
                            AvgAirTempAverage: + parseFloat(listItem.air_temp_avg).toFixed(2),
                            AccPrecipAverage: + parseFloat(listItem.precip_acc_avg).toFixed(2),

                        });
                        beginingDate = addDays(beginingDate, 1)
                    }))
            }
            setClimateHistory(fetchData);
        }

        fetchHailHistory();
    }, [location, filterDateRange]);

    return [ClimateHistory];
};


interface ClimateHistory {
    LocalDate: string;
    MinAirTempAverage: number;
    MaxAirTempAverage: number;
    AvgAirTempAverage: number;
    AccPrecipAverage: number;
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function getDateString(date) {
    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
}
