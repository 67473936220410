import React, { useEffect, useState } from 'react';
import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, NavbarText, Button, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import './Header.css';
import { useOktaAuth } from '@okta/okta-react';
// import { LinkContainer } from 'react-router-bootstrap'
import { UserClaims } from '@okta/okta-auth-js';

export const Header = ({ title = 'Title' }: any) => {

  const { oktaAuth, authState } = useOktaAuth();

  const [userInfo, setUserInfo] = useState<UserClaims>();
  const login = () => { oktaAuth.signInWithRedirect(); }
  const logout = () => { oktaAuth.signOut(); }
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  // const loginButton = authState.isAuthenticated ? <Button onClick={logout}>Logout</Button> : <Button onClick={login}>Sign In</Button>;

  useEffect(() => {
    if (authState) {
      if (!authState.isAuthenticated) {
        setUserInfo(undefined);
      } else {
        oktaAuth.getUser().then(info => {
          setUserInfo(info);
        });
      }
    }
  }, [oktaAuth, authState]); // Update if authState changes

  if (!authState) return null;
  return (
    <div>
      <Navbar className="navbar-custom" dark expand="md">
        <NavbarBrand href="/"><img className="header-logo" src={process.env.PUBLIC_URL + "/ProAg_Logo_4c_WhiteOutline 930X228.png"} alt="ProAg Logo"></img>
          <span className="header-title">{title}</span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav className="ms-auto" navbar >
            <NavItem>
              <NavLink target='new' href={process.env.REACT_APP_APPURL_IMAGERYEXPLORER}>Imagery Explorer</NavLink>
            </NavItem>
            <NavItem>
              <NavLink target='new' href={process.env.REACT_APP_APPURL_PLANETWATCHERSEXPLORER}>PlanetWatchers Explorer</NavLink>
            </NavItem>
            <NavItem>
              <NavLink target='new' href={process.env.REACT_APP_APPURL_CLAIMSMAPPING}>Claims Mapping</NavLink>
            </NavItem>
            <NavItem>
              <NavLink target='new' href={process.env.REACT_APP_APPURL_WEATHERIMPACTDASHBOARD}>Weather Impact Dashboard</NavLink>
            </NavItem>
          </Nav>
          <NavbarText >{authState.isAuthenticated ? <Button onClick={logout}>Logout</Button> : <Button onClick={login}>Sign In</Button>}</NavbarText>
          {/* <NavbarText >{authState.isAuthenticated ? <Button onClick={logout}>Logout</Button> : <Button onClick={login}>Sign In</Button>}{userInfo ? "  " + userInfo.name : ""}</NavbarText> */}
        </Collapse>
      </Navbar>
    </div>
  );
}