import { useEffect, useState } from 'react';
import Moment from 'moment';
import { useOktaAuth } from '@okta/okta-react';
import { API_ROOT } from '../api-config';
import { exit } from 'process';
import { Console } from 'console';

export const useDailyPrecipitation = (location, filterDateRange) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [dailyPrecipitation, setDailyPrecipitation] = useState<Object[]>([]);
    const [cummulativePrecipitation, setCummulativePrecipitation] = useState<Object[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchDailyPrecipitation() {
            setLoading(true);
            const url = `${API_ROOT}/api/athenium/getprecipitationhistory/${encodeURIComponent(location.latitude)}/${encodeURIComponent(location.longitude)}?startDate=${encodeURIComponent(Moment.utc(filterDateRange.startDate).format('MM/DD/YYYY'))}&endDate=${encodeURIComponent(Moment.utc(filterDateRange.endDate).format('MM/DD/YYYY'))}`

            setDailyPrecipitation([]);
            setCummulativePrecipitation([]);
            const dailyPrecip: Object[] = [];
            const cummulativePrecip: Object[] = [];
            var cummulativeDailyPrecip = 0;
            if (!authState) return null;
            if (authState.isAuthenticated) {
                const accessToken = oktaAuth.getAccessToken();
                await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => data.data.forEach(listItem => {
                        if (listItem.precipitationInches < 9999) {
                            dailyPrecip.push({
                                label: Moment.utc(new Date(listItem.dateGmt)).format('MM/DD/YYYY'),
                                y: parseFloat(listItem.precipitationInches)
                            })

                            cummulativeDailyPrecip += parseFloat(listItem.precipitationInches);
                            cummulativePrecip.push({
                                label: Moment.utc(new Date(listItem.dateGmt)).format('MM/DD/YYYY'),
                                y: cummulativeDailyPrecip
                            });
                        }
                    }))
            }
            setDailyPrecipitation(dailyPrecip);
            setCummulativePrecipitation(cummulativePrecip);
            setLoading(false)
        }

        fetchDailyPrecipitation();
    }, [location, filterDateRange]);

    return [isLoading, dailyPrecipitation, cummulativePrecipitation];
};

export const useDailyTemperature = (location, filterDateRange) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [dailyTemperature, setDailyTemperature] = useState<Object[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchDailyTemperature() {
            setLoading(true);
            const dayInterval = 30;
            const endDate = Moment(filterDateRange.endDate).isAfter(Moment().utc()) ? Moment().utc() : Moment(filterDateRange.endDate);

            const fetchData: Object[] = [];

            setDailyTemperature([]);

            for (let beginDate = Moment(filterDateRange.startDate); beginDate <= endDate;) {

                var queryEndDate = Moment(beginDate).add(dayInterval, 'days').toString()

                if (Moment.utc(queryEndDate).isSameOrAfter(endDate)) {
                    queryEndDate = endDate.toString();
                };


                 const url = `${API_ROOT}/api/athenium/gettemperaturehistory/${encodeURIComponent(location.latitude)}/${encodeURIComponent(location.longitude)}?startDate=${encodeURIComponent(Moment(beginDate).format('MM/DD/yyyy').toString())}&endDate=${encodeURIComponent(Moment(queryEndDate).format('MM/DD/yyyy').toString())}`
                 if (!authState) return null;
                    if (authState.isAuthenticated) {
                        const accessToken = oktaAuth.getAccessToken();
                        await fetch(url, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        })
                            .then(response => response.json())
                            .then(data => data.data.forEach(listItem => {
                                fetchData.push({
                                    label: Moment.utc(listItem.dateGmt).format('MM/DD/YYYY'),         
                                    y: [parseFloat(listItem.minTemperatureFahrenheit), parseFloat(listItem.maxTemperatureFahrenheit)]
                                });
                            }))
                        beginDate = Moment(beginDate).add(dayInterval, 'days');
                    }
            }
            setDailyTemperature(fetchData);
            setLoading(false)
        }

        fetchDailyTemperature();
    }, [location, filterDateRange]);

    return [isLoading, dailyTemperature];
};

export const useHailHistory = (location, filterDateRange) => {
    const { authState, oktaAuth } = useOktaAuth(); 
    const [HailHistory, setHailHistory] = useState<Object[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchHailHistory() {
            setLoading(true);

            const url = `${API_ROOT}/api/athenium/gethailhistory/${encodeURIComponent(location.latitude)}/${encodeURIComponent(location.longitude)}?startDate=${encodeURIComponent(Moment.utc(filterDateRange.startDate).format('MM/DD/YYYY'))}&endDate=${encodeURIComponent(Moment.utc(filterDateRange.endDate).format('MM/DD/YYYY'))}`

            const fetchData: Object[] = [];
            setHailHistory([]);
            if (!authState) return null;
            if (authState.isAuthenticated) {
                const accessToken = oktaAuth.getAccessToken();
                await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => data.hailHistoryReportData.forEach(listItem => {
                        if (Moment.utc(listItem.timestamp).isSameOrBefore(Moment.utc(filterDateRange.endDate))) {
                            fetchData.push({
                                label: Moment(listItem.timestamp).utc().format('MM/DD/YYYY'),
                                y: parseFloat(listItem.peril)
                            });
                        }
                    }))
            }
            setHailHistory(fetchData);
            setLoading(false);
        }

        fetchHailHistory();
    }, [location, filterDateRange]);

    return [isLoading, HailHistory];
};

export const useWindHistory = (location, filterDateRange) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [WindHistory, setWindHistory] = useState<Object[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchWindHistory() {
            setLoading(true);
            const url = `${API_ROOT}/api/athenium/getwindhistory/${encodeURIComponent(location.latitude)}/${encodeURIComponent(location.longitude)}?startDate=${encodeURIComponent(Moment.utc(filterDateRange.startDate).format('MM/DD/YYYY'))}&endDate=${encodeURIComponent(Moment.utc(filterDateRange.endDate).format('MM/DD/YYYY'))}`
            
            const fetchData: Object[] = [];

            setWindHistory([]);
            if (!authState) return null;
            if (authState.isAuthenticated) {
                const accessToken = oktaAuth.getAccessToken();
                await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => data.windHistoryReportData.forEach(listItem => {
                        if (Moment.utc(listItem.timestamp).isSameOrBefore(Moment.utc(filterDateRange.endDate))) {
                            fetchData.push({
                                label: Moment(listItem.timestamp).utc().format('MM/DD/YYYY'),
                                y: parseFloat(listItem.peril)
                            });
                        }
                    }))
            }
            setWindHistory(fetchData);
            setLoading(false)
        }

        fetchWindHistory();
    }, [location, filterDateRange]);

    return [isLoading, WindHistory];
};

export const useGrowingDegreeDayHistory = (location, filterDateRange) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [GrowingDegreeDayHistory, setGrowingDegreeDayHistory] = useState<Object[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchGrowingDegreeDayHistory() {
            setLoading(true);
            const url = `${API_ROOT}/api/athenium/getgrowingdegreedays/${encodeURIComponent(location.latitude)}/${encodeURIComponent(location.longitude)}?startDate=${encodeURIComponent(Moment.utc(filterDateRange.startDate).format('MM/DD/YYYY'))}&endDate=${encodeURIComponent(Moment.utc(filterDateRange.endDate).format('MM/DD/YYYY'))}`
            
            const fetchData: Object[] = [];

            setGrowingDegreeDayHistory([]);
            var cumulitiveGDD = 0;
            if (!authState) return null;
            if (authState.isAuthenticated) {
                const accessToken = oktaAuth.getAccessToken();
                await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => data.degreeDayData.forEach(listItem => {
                        cumulitiveGDD += parseFloat(listItem.growingDegreeDay);
                        fetchData.push({
                            label: Moment.utc(new Date(listItem.month + '/' + listItem.day + '/' + listItem.year)).format('MM-DD-YYYY'),
                            y: cumulitiveGDD
                        });
                    }))
            }
            setGrowingDegreeDayHistory(fetchData);
            setLoading(false)
        }

        fetchGrowingDegreeDayHistory();
    }, [location, filterDateRange]);

    return [isLoading, GrowingDegreeDayHistory];
};

export const useGaugeRisk = (location) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [isLoading, setLoading] = useState(true);

    type RiskScore = {
        peril: string,
        riskScore: number
    };

    const [riskScores, setRiskScores] = useState<RiskScore[]>([]);

    useEffect(() => {
        async function fetchRiskScores() {
            setLoading(true);
            const url = `${API_ROOT}/api/athenium/getriskscores/${location.latitude}/${location.longitude}`

            setRiskScores([]);
            if (!authState) return null;
            if (authState.isAuthenticated) {
                const accessToken = oktaAuth.getAccessToken();
                await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                    .then(response => response.json())
                    .then(data => data.forEach(listItem => {
                        var perilText

                        switch (listItem.peril) {
                            case "HURRICANE_WIND":
                                perilText = "HURRICANE";
                                break;
                            case "RIVER_FLOOD":
                                perilText = "FLOOD";
                                break;
                            default:
                                perilText = listItem.peril;
                                break;
                        }
                
                        setRiskScores(prevItems => [...prevItems, {
                            peril: perilText,
                            riskScore: listItem.riskScore
                        }]);
                    }))
            }
            setLoading(false)
        }

        fetchRiskScores();
    }, [location]);

    return [isLoading, riskScores];
};