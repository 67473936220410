import React, { CSSProperties, useState } from "react";
import { SoilMap } from '../../components/MapView/SoilMap';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner, Container, Row, Col,
} from "reactstrap";


const SoilProfile = ({
  title = "Title",
  fieldGeometry,
  showSoilProfileModal,
  setShowSoilProfileModal,
}) => {
  // const [modal, setModal] = useState(false);
  const [fieldSoilProfile, setFieldSoilProfile] = useState([]);
  const toggle = () => setShowSoilProfileModal(!showSoilProfileModal);

  if (fieldSoilProfile) {
    fieldSoilProfile.sort(function (a: any, b: any) {
      var aSize = a.Acres;
      var bSize = b.Acres;
      if (aSize > bSize) {
        return -1;
      }
      if (aSize < bSize) {
        return 1;
      }
      return 0;
    });
  }

  const Table = ({ data }) => (
    <table className="table table-sm table-responsive table-borderless table-striped ">
      <thead>
        <tr>
          <th></th>
          {/* <th>Code</th> */}
          <th>Description</th>
          <th>Acres</th>
          <th>Classification</th>
          <th>Slope</th>
          <th>Flooding Frequency</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row) => {
          const customStyle: CSSProperties = {
            backgroundColor: row.Color,
            color: row.Color,
          };
          return (
            <tr key={row.ObjectId}>
              <td style={customStyle}></td>
              {/* <td>{row.Code}</td> */}
              <td>{row.Description}</td>
              <td>{row.Acres}</td>
              <td>{row.FarmLandClassification}</td>
              <td>{row.SlopeGradient}</td>
              <td>{row.FloodingFrequency}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div>
      <Modal isOpen={showSoilProfileModal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Field Soil Profile</ModalHeader>
        <ModalBody>
          <Container>
            <Row className="row-margin-05" >
              <Col className="col-md-12  map-container" style={{ height: 320 }}>
                <SoilMap fieldGeometry={fieldGeometry} setFieldSoilProfile={setFieldSoilProfile} ></SoilMap>
              </Col>
            </Row>

            <Row className="row-margin-05" >
              <Col className="col-md-12 ">
                {fieldSoilProfile.length > 0 ? (
                  <Table
                    data={fieldSoilProfile.sort((a: any, b: any) =>
                      parseFloat(a.PercentofTotalAcres) <
                        parseFloat(b.PercentofTotalAcres)
                        ? 1
                        : -1
                    )}
                  />
                ) : (
                  <Spinner color="primary" />
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SoilProfile;
