import React from "react";
import CanvasJSReact from "../../charts/canvasjs.react";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { useDailyTemperature } from "../../hooks/useAthenium";
import { useAverageDailyTemperature } from "../../hooks/useClearAg";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DailyTemperature = ({
  title = "Title",
  location = { latitude: 45, longitude: -90 },
  filterDateRange,
}) => {
  const [isLoading, weatherData] = useDailyTemperature(location, filterDateRange);
  const [ averageTemperature] = useAverageDailyTemperature(
    location,
    filterDateRange
  );

  const options = {
    theme: "light1", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    height: 350,
    axisX: {
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    axisY: {
      title: "Temperature (°F)",
      suffix: "F",
      // maximum: 125,
      crosshair: {
          enabled: true,
          snapToDataPoint: true
      }
    },
    legend: {
      itemclick: function (e) {
        if (
          typeof e.dataSeries.visible === "undefined" ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }

        e.chart.render();
      },
    },
    data: [
      {
        type: "rangeArea",
        color: "#F6BCAF",
        name: "Normal Average Temperature",
        // xValueFormatString: "MMMM YYYY",
        // yValueFormatString: "#0.## °F",
        showInLegend: true,
        dataPoints: averageTemperature,
      },
      {
        type: "rangeColumn",
        //indexLabel: "{y[#index]}°",
        color: "#6D78AD",
        name: "Daily Temperature",
        // xValueFormatString: "MMMM YYYY",
        // yValueFormatString: "#0.## °F",
        showInLegend: true,
        // toolTipContent:
        //   "{label} </br> <strong>Temperature: </strong> </br> Min: {y[0]} °F, Max: {y[1]} °F",
        dataPoints: weatherData,
      },
    ],
  };

  return (
    <div>
      <Card>
        <CardBody className="text-center">
          <CardTitle align="center" tag="h5">
            {title}
          </CardTitle>
          {!isLoading ? (
            <CanvasJSChart options={options} />
          ) : (
            <Spinner color="primary" />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DailyTemperature;
