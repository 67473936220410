import React, { useEffect, useRef  } from 'react';
import { loadModules } from 'esri-loader';
import './MapView.css';

export const SoilMap = ({ fieldGeometry, setFieldSoilProfile }: any) => {
  const mapRef = useRef(null);

  useEffect(
    () => {
      // lazy load the required ArcGIS API for JavaScript modules and CSS
      loadModules([ 
        'esri/Map',
        'esri/views/MapView',
        'esri/identity/OAuthInfo',
        'esri/identity/IdentityManager',
        'esri/layers/GraphicsLayer',
      ], { css: true })
        .then(([Map, MapView, OAuthInfo, esriId, GraphicsLayer]) => {
          const oAuthInfo = new OAuthInfo({
            appId: process.env.REACT_APP_ARCGIS_PORTAL_ID,
            portalUrl: process.env.REACT_APP_ARCGIS_PORTAL_URL
          });
          esriId.registerOAuthInfos([oAuthInfo]);

          esriId
            .checkSignInStatus(oAuthInfo.portalUrl + '/sharing')
            .then(/*logged in*/)
            .catch(/*give user an option to sign in*/);

          const map = new Map({
            basemap: "satellite"
            // portalItem: {
            //   id: '3d7f967f8c07407997ee1085e838ec55',
            //   portal: oAuthInfo.portalUrl
            // }
          });

          const tempSoilLayer = new GraphicsLayer({
            opacity: 0.65
          })
          map.add(tempSoilLayer, 1);

          // load the map view at the ref's DOM node
          const view = new MapView({
            container: mapRef.current,
            popup: {
              dockEnabled: true,
              dockOptions: {
                // Disables the dock button from the popup
                buttonEnabled: false,
                // Ignore the default sizes that trigger responsive docking
                breakpoint: false
              }
            },
            map
          });

          getSoilData(tempSoilLayer, fieldGeometry);
          view.goTo(fieldGeometry.extent);

          async function getSoilData(tempSoilLayer, geo: any) {
            await loadModules([
              'esri/layers/FeatureLayer',
              'esri/Graphic',
              "esri/geometry/geometryEngine",
              'esri/layers/GraphicsLayer',
              "esri/Color",
            ], { css: true })
              .then(([FeatureLayer, Graphic, geometryEngine, GraphicsLayer, Color]) => {
                const soilLayer = new FeatureLayer({
                  // url: 'https://landscape11.arcgis.com/arcgis/rest/services/USA_Soils_Map_Units/mapserver/0'
                  url: process.env.REACT_APP_ARCGIS_FEATURELAYER_SOILLAYER
                })

                const soilProfileData: Object[] = [];
                
                tempSoilLayer.removeAll();

                const queryParams = soilLayer.createQuery();
                queryParams.geometry = geo;
                queryParams.outFields = '*';
                soilLayer.queryFeatures(queryParams).then((results: any) => {
                  var intersected = geometryEngine.intersect(results.features.map((feature) => {
                    return feature.geometry;
                  }), geo); 
                  
                  var featuresToDelete = Array();


                  results.features.forEach((feature, i) => {

                    if (intersected[i] != null) {
                      feature.geometry = intersected[i];
                    }
                    else {
                      featuresToDelete.push(i);
                    }
                  });

                  featuresToDelete.sort(function(a, b) {return b - a});

                  featuresToDelete.forEach((i) => {
                    results.features.splice(i,1);
                  })


                  const COLOR_OPTIONS = ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabebe', 
                  '#469990', '#e6beff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9', '#ffffff', '#000000'];

                  var colorGraphics = results.features.map((feature, i) => {
                    var color = i > ((COLOR_OPTIONS.length)-1) ? '#D3D3D3' : COLOR_OPTIONS[i];
            
                    var polygonSymbol = {
                      type: "simple-fill",  // autocasts as new SimpleFillSymbol()
                      color: new Color(color),
                      style: "solid",
                      outline: {  // autocasts as new SimpleLineSymbol()
                        color: "white",
                        width: 1
                      }
                    };

                    function formatContent(feature) {
                      var attributes = feature.graphic.attributes;
                      var contents =  "<table class='esri-widget__table' summary='List of attributes and values'><tbody>"
                      for (let attr in attributes) {
                        contents += `<tr><th>${attr}</th><td>${attributes[attr]}</td></tr>`;
                      }
                      contents += "</tbody></table>";
                      return contents;
                    }
            
                    soilProfileData.push({
                      "Color": polygonSymbol.color,
                      "Code": feature.attributes.musym,
                      "Description": feature.attributes.muname,
                      "Acres": geometryEngine.geodesicArea(feature.geometry, 'acres').toFixed(2),
                      "PercentofTotalAcres": (geometryEngine.geodesicArea(feature.geometry, 'acres') / geometryEngine.geodesicArea(geo, 'acres') * 100).toFixed(1),
                      "FarmLandClassification": feature.attributes.farmlndcl,
                      "AnnualPrecipitation": feature.attributes.map_r,
                      "AnnualWaterTable": feature.attributes.wtdepannmin,
                      "SlopeGradient": feature.attributes.slopegradwta.toFixed(3),
                      "FloodingFrequency": feature.attributes.flodfreqmax,
                      "ObjectId": feature.attributes.objectid
                    })

                    return new Graphic({
                      geometry: feature.geometry,
                      symbol: polygonSymbol,
                      attributes: feature.attributes,
                      popupTemplate: {
                        title: '{muname}',
                        content: formatContent,
                        outFields: ["*"]
                      }
                    });
                  });

                  setFieldSoilProfile(soilProfileData)
                  tempSoilLayer.addMany(colorGraphics);

                });
              })
            }


          return () => {
            if (view) {
              // destroy the map view
              view.destroy();
            }
          };
        });
    }, [fieldGeometry]
  );


  return <div className='map-view-component' ref={mapRef}></div>

};