import React, { useEffect, useState } from "react";
import CanvasJSReact from "../../charts/canvasjs.react";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { useGrowingDegreeDayHistory } from "../../hooks/useAthenium";
import { useAverageDailyTemperature } from "../../hooks/useClearAg";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const GrowingDegreeDayHistory = ({
  title = "Title",
  location = { latitude: 45, longitude: -90 },
  filterDateRange,
}) => {
  const [isLoading, dailyGDD] = useGrowingDegreeDayHistory(location, filterDateRange);
  const [dailyAverageTemp] = useAverageDailyTemperature(
    location,
    filterDateRange
  );
  const [GrowingDegreeDayHistory, setGrowingDegreeDayHistory] = useState<
    Object[]
  >([]);

  useEffect(() => {
    const fetchData: Object[] = [];

    setGrowingDegreeDayHistory([]);
    var cumulitiveGDD = 0;

    for (var listItem of dailyAverageTemp) {
      var minTemp = Object.values(listItem)[1][0];
      var maxTemp = Object.values(listItem)[1][1];
      var averageGDD = Math.round((maxTemp + minTemp) / 2 - 50);
      averageGDD = averageGDD > 0 ? averageGDD : 0;
      cumulitiveGDD += averageGDD;
      fetchData.push({
        label: Object.values(listItem)[0],
        y: cumulitiveGDD,
      });
    }
    setGrowingDegreeDayHistory(fetchData);
  }, [dailyAverageTemp]);

  const options = {
    theme: "light1", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    height: 350,
    axisX: {
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    axisY: {
      title: "Growing Degree Days",
    },
    legend: {
      itemclick: function (e) {
        if (
          typeof e.dataSeries.visible === "undefined" ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }

        e.chart.render();
      },
    },
    data: [
      {
        type: "line",
        //indexLabel: "{y[#index]}°",
        color: "#6D78AD",
        name: "Daily GDD",
        showInLegend: true,
        xValueFormatString: "MMMM YYYY",
        toolTipContent:
          "{label} </br> <strong>Growing Degree Units: </strong> {y} °F",
        dataPoints: dailyGDD,
      },
      {
        type: "line",
        //indexLabel: "{y[#index]}°",
        color: "red",
        name: "Normal Average GDD",
        showInLegend: true,
        xValueFormatString: "MMMM YYYY",
        toolTipContent:
          "{label} </br> <strong>Growing Degree Units: </strong> {y} °F",
        dataPoints: GrowingDegreeDayHistory,
      },
    ],
  };

  return (
    <div>
      <Card>
        <CardBody className="text-center">
          <CardTitle align="center" tag="h5">
            {title}
          </CardTitle>
          {!isLoading ? (
            <CanvasJSChart options={options} />
          ) : (
            <Spinner color="primary" />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default GrowingDegreeDayHistory;
