import React, { useEffect, useState } from "react";
import CanvasJSReact from '../../charts/canvasjs.react'
import { Card, CardBody, CardTitle, Spinner } from 'reactstrap';
import { useDailyPrecipitation } from '../../hooks/useAthenium';
import { useClimateHistory } from '../../hooks/useClearAg';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DailyPrecipitation = ({ title = 'Title', location = { latitude: 45, longitude: -90 }, filterDateRange }) => {

    const [isLoading, dailyPrecipitation, dailyAccumulatedPrecipitation] = useDailyPrecipitation(location, filterDateRange);
    const [climateHistory] = useClimateHistory(location, filterDateRange);
    const [precipHistoryAverage, setPrecipHistoryAverage] = useState<Object[]>([]);

    useEffect(() => {
        async function buildDataSet() {
            var accumPrecip = 0;

            const fetchData: Object[] = [];

            for (var listItem of climateHistory) {
                accumPrecip += listItem.AccPrecipAverage
                fetchData.push({
                    label: listItem.LocalDate,
                    y: accumPrecip
                });
            };
            setPrecipHistoryAverage(fetchData)
        }
        buildDataSet();
    }, [climateHistory])

    const options = {
        theme: "light1", // "light1", "dark1", "dark2"
        animationEnabled: true,
        zoomEnabled: true,
        exportEnabled: true,
        height: 350,
        axisX: {
            crosshair: {
                enabled: true,
                snapToDataPoint: true
            }
        },
        axisY: {
            title: "Daily Precipitation",
            suffix: "in",
            crosshair: {
                enabled: true,
                snapToDataPoint: true
            }
        },
        axisY2: {
            title: "Accumulated Precipitation",
            suffix: "in",
            crosshair: {
                enabled: true,
                snapToDataPoint: true
            }
        },
        toolTip: {
            shared: true
        },
        legend: {

            itemclick: function (e) {
                if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                    e.dataSeries.visible = false;
                } else {
                    e.dataSeries.visible = true;
                }

                e.chart.render();
            }
        },
        data: [{
            type: "column",
            axisYIndex: 0,
            name: "Daily Precipitation",
            showInLegend: true,
            dataPoints: dailyPrecipitation
        }, {
            type: "line",
            axisYIndex: 1,
            axisYType: "secondary",
            name: "Normal Average Precipitation",
            showInLegend: true,
            dataPoints: precipHistoryAverage
        }, {
            type: "line",
            axisYIndex: 1,
            axisYType: "secondary",
            name: "Accumulated Precipitation",
            showInLegend: true,
            dataPoints: dailyAccumulatedPrecipitation
        }]
    }

    return (
        <div>
            <Card>
                <CardBody className="text-center">
                    <CardTitle align="center" tag="h5">{title}</CardTitle>
                    {!isLoading ? <CanvasJSChart options={options} /> : <Spinner color="primary" />}
                </CardBody>
            </Card>
        </div>
    )

}

export default DailyPrecipitation;